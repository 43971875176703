.ProductBenefits {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    @include mobile {
        overflow-x: auto;
        justify-content: flex-start;
    }
    &-Item {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: $black;
        display: flex;
        align-items: center;

        @include mobile {
            min-width: 85%;
        }
         img {
            margin-right: 10px;
            width: auto;
            height: auto;
            max-width: 100px;
            max-height: 100px;

            @include wide-desktop {
                margin-right: 20px;
            }
        }
    }
}
