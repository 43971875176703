/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
  * ScandiPWA - Progressive Web App for Magento
  *
  * Copyright © Scandiweb, Inc. All rights reserved.
  * See LICENSE for license details.
  *
  * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
  * @package scandipwa/scandipwa
  * @link https://github.com/scandipwa/scandipwa
  */

.Field {
    &_type {
        &_numberWithControls {
            [type="number"] {
                color: $black;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                min-width: 50px;
                pointer-events: initial;
                height: 46px;
                    
                &:focus {
                    border: 0;
                }

                ~button {
                    width: 46px;
                    min-width: 46px;
                    height: 46px;
                    cursor: pointer;
                    transition-duration: .25s;
                    transition-timing-function: ease-out;
                    transition-property: background-color, color, border;
                    will-change: background-color, color, border;

                    &:first-of-type {
                        border-radius: 0 0 5px 0;
                    }

                    &:last-of-type {
                        border-radius: 0;
                    }

                    &:hover {
                        background-color: var(--hollow-button-hover-background);
                        border-color: var(--hollow-button-hover-border);

                        svg {
                            fill: $primary-color-1;
                        }
                    }
                }
            }
        }
    }
}
