/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.StyleGuidePage {
    .Button {
        &_isHovered {
            &.Button_isHollow {
                color: var(--hollow-button-hover-color);
            }
        }
    }

    &-MobileTextStyles {
        h4 {
            font-size: var(--h4-font-size-mobile);
        }

        h5 {
            font-size: var(--h5-font-size-mobile);
        }

        h6 {
            font-size: var(--h6-font-size-mobile);
        }
    }
    .paragraph-l {
        @include paragraph-l();
    }
    .paragraph-m {
        @include paragraph-m();
    }
    .paragraph-s {
        @include paragraph-s();
    }
    .paragraph-xs {
        @include paragraph-xs();
    }
}
