.CategorySeoTextTop {
    a {
        color: $text-color-2;
        font-size: inherit;

    }

    p {
        color: $text-color-2;
        margin-bottom: 20px;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
    }

    .ShowMoreLessContent-Content_isExpanded {
        p {
            &:last-of-type {
                margin-bottom: 50px;

                @include mobile {
                    margin-bottom: 20px;
                }
            }
        }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
        margin-bottom: 20px;

        @include mobile {
            font-size: 32px;
            line-height: 120%;
        }
    }

    &-Intro {
        p {
            font-size: 16px;
        }
    }

    &-2Columns {
        @include desktop {
            display: flex;
            column-gap: 21px;
            margin-top: 50px;
        }

        > div {
            @include desktop {
                width: 50%;
            }
        }
    }
}
