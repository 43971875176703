/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

[dir="ltr"] .SearchField {
    @include desktop {
        max-width: unset;
    }


    &-Input {
        border-radius: 0 0 0 10px;

        @include desktop {
            height: 42px;
        }
    }

    &_isActive {
        .SearchOverlay {
            &-Results {
                @include desktop {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

    &_isVisible {
        @include mobile {
            margin-top: 60px;
        }

        input {
            @include mobile {
                border: 1px solid $black;
                border-radius: 0 0 0 10px;
                background-color: $background-color-1;
                height: 42px;
            }

            &::placeholder {
                opacity: 1;
            }

            &.SearchField-Input_isActive,
            &:focus {
                border: 1px solid $primary-color-1;

                +.SearchField-CloseIcon,
                +.SearchField-SearchIcon {
                    &::before {
                        background-color: $primary-color-1;
                    }
                }
            }
        }
    }

    &-SearchIcon,
    &-CloseIcon {
        right: 9px;

        &::before {
            content: '';
            width: 1px;
            height: calc(100% + 16px);
            display: block;
            position: absolute;
            top: -8px;
            left: -9px;
            background-color: $black;

            @include mobile {
                height: calc(100% + 16px);
                top: -8px;
            }
        }
    }
}