/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.CurrencySwitcher {
    --selector-width: 56px;

    display: flex;
    justify-content: flex-end;
    margin-inline-start: auto;
    width: 33%;
    border-block-end: 1px solid var(--primary-divider-color);

    @include mobile {
        margin: 0;
        padding-block-end: 1px;
        border: none;
        width: auto;
        position: absolute;
        right: 5px;
    }

    .Field {
        margin-block-start: 0;

        &Select {
            @include desktop {
                width: var(--selector-width);
                padding-inline-start: 12px;

                &-Clickable {
                    .ChevronIcon {
                        height: 10px;
        
                        path {
                            stroke: $secondary-color-5;
                        }
                    }
                     
                    #CurrencySwitcherList {
                        background-color: $primary-color-2;
                        max-height: 38px;
                        color: $secondary-color-5;
                    }
                }

                .ChevronIcon {
                    inset-inline-end: 0;
                }
            }

            @include mobile {
                &-Select {
                    padding-right: 0;
                    padding-left: 5px;
                }

                #CurrencySwitcherList {
                    border-bottom: none;
                    margin-right: -14px;
                }

                &-Clickable {
                    .ChevronIcon {
                        height: 10px;
                    }
                }

                .ChevronIcon {
                    inset-inline-end: 0;
                }
            }

            &-Select {
                border: none;
                font-size: 12px;
                font-weight: 400;
                padding: 0;
                margin: 0;
                opacity: 1;
                padding-inline-start: 0;
                line-height: 23px;
                min-height: 0;
            }

            &-Options {
                min-width: var(--selector-width);
                width: min-content;

                &_isExpanded {
                    border-block-start: 1px solid var(--primary-divider-color);
                }

                &Wrapper {
                    min-width: var(--selector-width);
                    width: min-content;
                }
            }

            &-Option {
                line-height: 28px;
                padding: 6px 12px;
                font-size: 12px;
            }

            &::after {
                height: 13px;
                inset-inline-end: 0;
                width: 13px;
            }
        }
    }

    @include desktop {
        border-block-end: none;

        .FieldSelect {
            &-Select {
                border: none;
                font-size: 12px;
                padding: 0;
            }

            &-Options {
                min-width: var(--selector-width);
                width: min-content;

                &_isExpanded {
                    border-block-start: 1px solid var(--primary-divider-color);
                }

                &Wrapper {
                    min-width: var(--selector-width);
                    width: min-content;
                }
            }

            &-Option {
                line-height: 28px;
                padding: 6px 12px;
                font-size: 12px;
            }
        }
    }
}
