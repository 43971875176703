/* stylelint-disable csstools/use-logical */

#html-body {
    .PromoBrochure {
        @include mobile {
            h2 {
                line-height: 100%;
                
                span {
                    line-height: 100%;
                }
            }

            ul {
                li, li:last-child {
                    line-height: 200%;
                    margin-bottom: 8px;
                }
            }
        }

        &-Menu {
            padding: 0 8px;
            display: flex;
            justify-content: center;
            gap: 18px;
            list-style-type: none;
            
            @include mobile {
                flex-wrap: wrap;
                gap: 8px;
            }
    
            &Element {
                margin-bottom: 0;
    
                &:last-child {
                    margin-bottom: 0;
                }
    
                &::before {
                    content: none;
                }
            }
        }
        
        &-HeaderBanner {
            .pagebuilder-poster-overlay {
                display: flex;
                align-items: center;
            }
        
            .pagebuilder-button-primary {
                @include button;
            }
        }

        &-TileBanner {
            @include mobile {
                margin: 0;
            }

            .pagebuilder-poster-content, .pagebuilder-collage-content {
                display: flex;
                flex-direction: column-reverse;
                align-items: flex-start;
            }
        
            .pagebuilder-button-secondary {
                font-size: 20px;
                letter-spacing: 1px;
                font-weight: 400;
                color: $white;
                background-color: $primary-color-2;
                padding: 1px 18px;
                border-radius: 0px 0px 10px 0px;
            }
        }

        &-SliderSection {
            .pagebuilder-column {
                @include mobile {
                    min-height: auto;
                }
            }
            
            .ProductSlider {
                .slick-list {
                    padding: 20px 0;
                }
            }
        }
    }
}
