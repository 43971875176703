/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --top-promo-block-height: 0px;
    --header-total-height: calc(var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height) + env(safe-area-inset-top) + var(--top-promo-block-height));

    @include mobile {
        --header-nav-height: 50px;
        --header-height: 50px;
    }

    @include desktop {
        --header-nav-margin: 15px;
        --header-top-menu-height: 38px;
        --header-store-switcher-height: 50px;
        --header-height: calc(var(--header-nav-height) + var(--header-nav-margin) + var(--header-top-menu-height) + var(--menu-total-height) + var(--header-store-switcher-height));
    }
}

.Header {
    border-bottom: 0;
    filter: drop-shadow(0px 0px 20px rgba(128, 18, 47, 0.1));

    &_name {

        &_filter,
        &_category {
            @include mobile {
                filter: none;
            }
        }

        &_menu,
        &_search {

            &,
            .Header-Nav {
                @include mobile {
                    background: $background-color-1;
                }
            }
        }
    }

    &-Wrapper {
        &_name {
            &_menu {
                @include mobile {
                    inset-block-start: 30px;
                }
            }

            &_menu,
            &_search {
                @include mobile {
                    background: $background-color-1;
                }
            }
        }
    }

    &-Nav {
        @include desktop {
            padding-inline: $content-wrapper-padding-desktop;
            margin-block-start: var(--header-nav-margin);
            grid-template-columns: minmax(var(--header-logo-width), 1fr) minmax(auto, 724px) 1fr;
            gap: 10px;
        }
    }


    &-TopMenu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        min-height: 38px;
        max-height: 68px;
        max-width: 100%;
        padding-block-start: unset;
        background-color: $primary-color-2;
    }

    &-MailWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 33%;
    }

    &-MailLink {
        color: $secondary-color-5;
        font-size: 12px;
        margin-inline-start: 4px;

        &:hover {
            color: $secondary-color-4;
        }
    }

    &-News {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: var(--content-wrapper-width);
        padding-inline: $content-wrapper-padding-desktop;
        max-height: 38px;

        p {
            font-size: 12px;
            color: $secondary-color-5;
            margin-block-end: 0;
        }
    }

    &-Title {
        @include mobile {
            font-weight: 300;
            font-size: 24px;
            line-height: 120%;
            letter-spacing: -0.03em;
            color: $black;
            inset-block-start: 3px;
        }
    }

    &-Button {
        &_type {
            &_back {
                @include mobile {
                    inset-inline-start: -8px;
                }

                .ChevronIcon {
                    width: 15px;
                    height: 15px;
                }
            }

            &_close {
                @include mobile {
                    inset-inline-start: -5px;
                }

                .CloseIcon {
                    width: 24px;
                    height: 24px;
                }
            }

            &_minicart {
                @include desktop {
                    margin-inline-start: 20px;
                    height: auto;
                    width: fit-content;
                }
            }
        }
    }

    &-StoreSwitcherWrapper {
        background-color: $secondary-color-4;
        width: 100%;
        height: var(--header-store-switcher-height);
        max-width: 100%;
    }

    &-StoreSwitcher {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        max-width: var(--content-wrapper-width);
        padding-inline: $content-wrapper-padding-desktop;
        margin-inline: auto;
    }

    &-AboutUsButton {
        height: 30px;
        padding: 14px 35px;
        background-color: transparent;
        border-color: $black;
        color: $black;
        min-width: 100px;
        font-weight: 600;

        &:hover {
            height: 30px !important;
            padding: 14px 35px !important;
            border-color: var(--button-hover-color) !important;
        }
    }

    &-LogoWrapper {
        @include desktop {
            grid-column: 1 / 2;
            position: absolute;
            min-height: unset;
            inset-block-start: 50%;
            transform: translateY(-50%);
        }

        @include mobile {
            max-height: 50px;
        }
    }

    &-MyAccountContainer {
        display: flex;
        margin-inline-start: 20px;
    }

    &-MyAccountWrapper,
    &-MinicartButtonWrapper {
        font-size: 12px;
        line-height: 140%;

        @include desktop {
            display: flex;
            align-items: center;
            flex-direction: column;

            &:hover {
                cursor: pointer;

                svg {
                    fill: $primary-color-1;
                }

                .CartIcon path {
                    stroke: $primary-color-1;
                }

                span:not(.Header-MinicartItemCount) {
                    color: $primary-color-1;
                }
            }
        }
    }

    &-IconsWrapper {
        justify-content: flex-end;
    }

    &-MinicartButtonWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-MinicartButtonInnerWrapper {
        height: 24px;
        width: 24px;
    }

    &-Minicart {
        &ItemCount {
            background: $black;

            @include desktop {
                inset-block-start: -10px;
                inset-inline-end: -10px;
            }
        }
    }

    &-LastOrders {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $black;
        font-size: 12px;
        font-weight: 400;
        line-height: 140%;

        &:hover {
            cursor: pointer;
            color: $primary-color-1;

            svg {
                path {
                    stroke: $primary-color-1;
                }
            }
        }
    }

    &_isCheckout {
        .Header {
            &-LogoWrapper {
                inset-inline-start: 20px;
                position: absolute;
            }

            &-Nav {
                margin-bottom: 10px;
            }
        }
    }

    &-TopPromo {
        width: 100vw;
        text-align: center;
        padding: 5px;

        @include mobile {
            padding: 10px;
        }

        p {
            display: inline;
            margin-bottom: 0;
            font-size: 15px;
        }
    }
}