/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.contacts-wrapper {
    @include mobile {
        padding: 0 $content-wrapper-padding-mobile;
    }

    @include desktop {
        display: flex;
        column-gap: 20px;
    }

    li {
        @include mobile {
            padding: 14px 0;
            margin-bottom: 0;
            border-bottom: 1px solid $secondary-color-4;
            display: flex;
            align-items: center;

        }

        img {
            @include mobile {
                max-width: 20px;
                margin-right: 20px;

            }

            @include desktop {
                display: none;
            }
        }

        &::before {
            display: none;
        }
    }

    a {
        @include mobile {
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
            color: $black;
        }
    }
}
