/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.StoreSwitcher {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    
    &-Switch {
        color: $black;
        font-size: 12px;
        text-transform: uppercase;
        padding: 8px 50px;
        letter-spacing: 0.6px;
        white-space: nowrap;

        &_isActive {
            background-color: $white;

            &:nth-child(1) {
                border-radius: 10px 0 0 0;
            }

            &:nth-child(2) {
                border-radius: 0 10px 0 0;
            }
        }
    }
}
