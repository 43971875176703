/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --color-pagination-link-text: var(--imported_pagination_color, #{$black});
    --pagination-line-height: var(--imported_pagination_line_height, 20px);
    --pagination-font-size: var(--imported_pagination_font_size, 14px);
    --pagination-font-style: var(--imported_pagination_font_style, normal);
}

[dir="ltr"] .PaginationLink {
    border: 0;
    border-radius: 10px;
    color: var(--color-pagination-link-text);
    font-size: var(--pagination-font-size);
    font-style: var(--pagination-font-style);
    display: block;
    line-height: var(--pagination-line-height);
    margin-inline-end: 0;
    padding: 10px;
    text-align: center;
    font-weight: 400;
    min-width: 30px;
    height: 40px;

    &:hover,
    &_isCurrent {
        color: $primary-color-1;
        background-color: $background-color-1;
        text-decoration: none;
    }

    &_isCurrent {
        font-weight: 600;
        font-size: 16px;
    }

    &_isArrow {
        padding: 6px 9px;
        margin: 0 5px;
        display: flex;
        align-items: center;


        &:hover,
        &:focus {
            border-color: transparent;

            svg {

                fill: none;

                path {
                    stroke: var(--primary-base-color);
                }
            }
        }
    }
}
