:root {
    --scrollbar-width: 10px;
    --scrollbar-height: 10px;
}

::-webkit-scrollbar {
    width: var(--scrollbar-width);
    height: var(--scrollbar-height);
}

::-webkit-scrollbar-track {
    background: $secondary-color-5;
}

::-webkit-scrollbar-thumb {
    background: $text-color-3;
}
