/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Banner {
    &-Wrapper {
        min-height: 270px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px auto 50px;

        @include mobile {
            min-height: 150px;
            margin: 20px auto;
        }

        .Image {
            position: absolute;
            top: 0;
        }
    }

    &-Content {
        --color-variant: #{$primary-color-2};

        text-align: center;

        h2,
        p,
        a {
            color: var(--color-variant);
        }

        a {
            border-color: var(--color-variant);

            &.Button {
                @include mobile {
                    height: 28px;
                    font-size: 9px;
                    line-height: 110%;
                    letter-spacing: 0.05em;
                    width: auto;
                    border-width: 1px;
                    min-width: 101px;
                }

                &:hover {
                    border-color: var(--color-variant);
                    color: var(--color-variant);
                }
            }
        }

        h2 {
            margin: 0 0 16px;

            @include mobile {
                font-weight: 300;
                font-size: 26.6667px;
                line-height: 120%;
                letter-spacing: -0.03em;
                margin: 0 0 9px;
            }
        }

        p {
            @include paragraph-l();

            margin-bottom: 0;

            @include mobile {
                font-weight: 400;
                font-size: 10px;
                line-height: 140%;
            }
        }

        &_colorVariant {
            &_primary {
                --color-variant: #{$primary-color-2};
            }

            &_black {
                --color-variant: #{$black};
            }

            &_white {
                --color-variant: #{$white};
            }
        }
    }
}
