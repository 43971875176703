/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.PostCard {
    padding-bottom: 40px;

    @include mobile {
        min-width: 70vw;
    }

    &-Link {
        display: block;
    }

    &-Content {
        margin-top: 20px;
        padding: 0 10px;
    }
    
    & &-Title {
        margin: 12px 0;

        @include mobile {
            font-size: 24px;
        }
    }

    &-Info {
        color: $text-color-2;
        font-size: 18px;

        @include mobile {
            font-size: 16px;
        }
    }

    &-Description {
        font-size: 18px;
        color: $text-color-2;

        @include text-in-x-lines(4);
        
        @include mobile {
            font-size: 16px;
            line-height: 120%;

            @include text-in-x-lines(5);
        }
    }

    &-ButtonWrapper {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 10px;

        .Button {
            font-size: 14px;
        }

        @include mobile {
            left: 0;
        }
    }
}
