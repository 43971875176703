/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
* ScandiPWA - Progressive Web App for Magento
*
* Copyright © Scandiweb, Inc. All rights reserved.
* See LICENSE for license details.
*
* @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
* @package scandipwa/base-theme
* @link https://github.com/scandipwa/base-theme
*/

.FreeShipping {
    &-Wrapper {
        display: flex;
        padding: 10px 20px;
        align-items: center;
        gap: 10px;
        border-radius: 0px 6px 0px 0px;
        background: $secondary-color-5;

        .FreeShipping {
            &-Heading {
                font-size: 16px;
                font-weight: 400;
            }
        
            &-Value {
                font-size: 16px;
                font-weight: 700;
            }
        }
        
        @include mobile {
            margin: 10px 0;

            .FreeShipping {
                &-Heading {
                    font-size: 14px;
                }
            
                &-Value {
                    font-size: 14px;
                }
            }
        }
    }
}
