/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --table-body-border: 2px solid #{$background-color-1};

    @include mobile {
        --table-body-border: 2px solid #{$secondary-color-4};
    }
}

table {
    thead th {
        background-color: $white;
        border-radius: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        padding: 12px 10px 22px;

    }

    thead tr {
        border-bottom: var(--table-body-border);
    }

    tbody tr:not(:last-child) {
        border-block-end: var(--table-body-border);
    }

    tr {
        page-break-inside: avoid;
    }

    td,
    th {
        padding: 12px 10px;
        text-align: start;
        min-width: 120px;
        vertical-align: top;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;

        @include mobile {
            min-width: 140px;
        }
    }

    td {
        font-weight: 700;
    }

    tbody {
        th {
            @include mobile {
                padding-left: 0;
            }
        }
    }
}

.Table-Wrapper {
    max-width: 100%;
    overflow-x: auto;
}
