/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CarouselScrollItem {
    --option-border-color: transparent;
    --carousel-scroll-gap: 13px;
    
    width: 124px;
    height: 124px;
    border-width: 2px;
    border-radius: 0;
    filter: opacity(1);

    &_isActive {
        border-color: $secondary-color-4;
    }

    &:focus,
    &:hover {
        border-color: $secondary-color-4;
    }

    .ProductGalleryThumbnailImage {
        padding: 0;
    }
    
    &-Additional {
        overflow: hidden;
    }
}
