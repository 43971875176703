/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --menu-item-height: 20px;
    --menu-total-height: 51px;
    --menu-height: 34px;
}

.Menu {
    &Page {
        @include mobile {
            margin-top: -42px;
            padding-top: calc(var(--header-total-height) + 32px);
            background: $background-color-1;
        }
    }

    &-SubCategories {
        @include desktop {
            padding-inline: 0;
        }
    }

    &-MenuWrapper {
        @include desktop {
            padding: 0 $content-wrapper-padding-desktop;
            min-height: var(--menu-height);
            margin-top: 17px;
        }
    }

    &-Sub {
        &Menu {
            &_isVisible {
                @include mobile {
                    background: $background-color-1;
                }
            }
        }
    }

    &-MainCategories {
        @include mobile {
            background: $white;
            padding-top: 14.5px;
        }
    }

    &-RecentPurchasesWrapper {
        @include mobile {
            padding: 14px 0;
            margin: 0 $content-wrapper-padding-mobile;
            border-bottom: 1px solid $secondary-color-4;
            display: flex;
            align-items: center;
        }

        svg {
            @include mobile {
                margin-right: 20px;
            }
        }
    }

    &-RecentPurchases {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        color: $black;
    }

    &-RedirectWrapper {
        text-align: center;
        padding: 12px $content-wrapper-padding-desktop 80px;

        .Button {
            width: auto;
        }
    }

    &-Item {
        @include mobile {
            margin-bottom: 0;
        }

        &:not(:first-of-type) {
            @include desktop {
                margin-inline-start: 40px;
            }
        }

        &:last-child {
            @include mobile {
                margin-bottom: 0;
            }
        }

        &Caption {
            @include mobile {
                margin-inline: 14px;
            }

            @include narrow-desktop {
                padding-bottom: 3px !important;
                font-size: 13px;
            }

            &_isSystemScale {
                padding-bottom: 3px !important;
                font-size: 13px;
                margin-top: 0;
            }

            &_isSecondLevel {
                font-weight: 400;
            }

            &_type {
                &_main {
                    color: $black;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 110%;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;

                    @include desktop {
                        padding: 0 0 25px;
                    }

                    @include mobile {
                        border-bottom: 1px solid $secondary-color-4;
                        padding: 20.5px 0;
                    }

                    svg {
                        width: 25px;
                        height: 25px;
                        fill: #000000;
                        top: -5px
                    }
                }
            }
        }

        &_Promotions {

            a,
            figcaption {
                color: #80122f;
                font-weight: 600;
            }
        }
    }
}