/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --search-overlay-offset: 40px;
    --search-item-hover-color: transparent;

    @include mobile {
        --search-overlay-offset: 14px;
    }
}

.SearchOverlay {
    &_isVisible {
        @include mobile {
            margin-block-start: 52px;
        }
    }

    &-CategoriesList {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        list-style: none;

        &Item {
            font-size: 16px;
            margin: 0;
            padding: 10px var(--search-overlay-offset);

            @include text-in-x-lines(1);

            &:hover {
                background-color: var(--search-item-hover-color);
            }
        }

        li {
            margin-block-end: 0;

            &::before {
                content: ""
            }
        }
    }

    &-CategoriesTitle {
        color: $black;
        font-size: 20px;
        font-weight: 600;
        margin: 0 0 10px;
        padding: 0 var(--search-overlay-offset);
    }

    &-HorizontalLine {
        background-color: $secondary-color-4;
        height: 1px;
        margin: 20px var(--search-overlay-offset);
    }

    &-Results {
        padding: 15px 0;

        @include desktop {
            border: 1px solid $secondary-color-4;
            border-top: 0;
            box-shadow: 0 0 20px rgba(128, 18, 47, 0.1);
            border-radius: 0 0 0 10px;
            max-height: calc(100vh - var(--header-total-height));
            display: flex;
            flex-direction: column;
        }

        &::before {
            content: '';
            width: 1px;
            height: 10px;
            display: block;
            position: absolute;
            inset-start: -6px -1px;
            background-color: $secondary-color-4;
        }

        ul {
            @include mobile {
                max-height: 250px;
                overflow-y: auto;
            }
        }

        .NoResults {
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
            display: flex;
            align-items: center;

            svg {
                margin-inline-end: 12px;

                path {
                    stroke: $black;
                }
            }
        }
    }
}
