/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.FreeGiftItem {
    border-bottom: 2px solid var(--powder-dark, #EFC1CE);

    &:last-child {
        border-bottom: none;
    }
    
    &-Wrapper {
        min-height: 100px;
        display: grid;
        grid-gap: 12px;
        padding: 10px 14px 10px 10px;
        background: var(--color-white);
        align-items: center;
        grid-template-columns: minmax(100px, 4fr) 2fr 1fr;

        &_isMobileLayout {
            display: flex;
            flex-direction: column;
            padding: 10px 0;
        }
    }

    &-ProductInfo {
        display: flex;
    }

    &-ProductActions {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-MobileActions {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: 16px;
    }

    &-Price {
        text-align: end;
        font-size: 24px;
        font-weight: 600;
    }

    &-Title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-inline-start: 20px;
    }

    
    &-Heading {
        font-size: 16px;
    }
    
    &-Subheading {
        font-size: 14px;
        color: $text-color-2;
    }

    &-Picture {
        width: 96px;
        min-width: 96px;
        height: 96px;
        align-self: flex-start;

        img {
            object-position: top;
        }

        &_isMobileLayout {
            min-width: 64px;
            width: 64px;
            height: 64px;
        }
    }

    &-Button {
        min-width: 133px;
        padding: 0 6px;

        @include mobile {
            min-width: 145px;
            width: auto;
        }

        span {
            color: $primary-color-1;
            font-size: 16px;
            font-weight: 400;
            margin-inline-end: 6px;
        }

        .CartIcon {
            path {
                stroke: $primary-color-1;
            }
        }
    }

    .Button {
        &_isAdded {
            pointer-events: none;
        }
    }
}
