:root {
    --font-size-mobile: 14px;
    --font-size-desktop: 16px;
}

.customFontSizeMobile {
    @include mobile {
        font-size: var(--font-size-mobile);
    }
}

.customFontSizeDesktop {
    @include desktop {
        font-size: var(--font-size-desktop);
    }
}
