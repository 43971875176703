/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.LinkBlank {
    position: relative;
    
    &:hover, &:focus, &:active {
        .LinkBlank-NewTabTooltip {
            display: block;
            z-index: 10000;
            position: absolute;
            background-color: $background-color-1;
            top: calc(100% + 5px);
            left: calc(100% + 5px);
            width: max-content;
            color: $black;
            font-size: 12px;
            text-align: center;
            padding: 3px 5px;
            border: 1px solid $secondary-color-4;
            border-radius: 0 6px 6px 6px;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            font-weight: 400;
            line-height: 14px;

            &::after {
                content: '';
                background-color: $secondary-color-4;
                height: 12px;
                left: -6px;
                position: absolute;
                top: -6px;
                width: 12px;
                -webkit-transform: rotate(-45deg);
            }
        }
    }
    
    &-NewTabTooltip {
        position: absolute;
        left: -9000px;
        width: 0;
        overflow: hidden;
    }
}
