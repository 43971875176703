/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

[dir="ltr"] .ExpandableContentShowMore {
    &-ShowAllButton.Button_likeLink {
        border-bottom: 0;
        font-size: 14px;
        line-height: 110%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin-top: 20px;
        font-weight: 400;
    
        &:not([disabled]) {

            &:active,
            &:focus,
            &:hover {
                @include desktop {
                    border-bottom: 0;
                }
            }
        }
    }
}
