/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.CmsPage {
    &-Wrapper {
        @include desktop {
            padding-inline: $content-wrapper-padding-desktop;
        }
    }

    &-Content {
        h2 {
            @include mobile {
                text-align: left;
            }
        }

        h3.ProductSlider-Title {
            @include mobile {
                font-size: 32px;
                line-height: 120%;
                letter-spacing: -0.03em;
            }
        }
    }

    
    @include mobile {
        h3 {
            font-size: 32px;
        }

        .pagebuilder-column-line {
            flex-wrap: wrap;
        }
    }

    h1, h2, h3, h4 {
        margin-top: 0;
    }
}

#html-body {
    .CmsPage {
        @include mobile {
            &-Wrapper {
                padding-inline: $content-wrapper-padding-mobile-small;
            }
    
            .pagebuilder-column {
                width: 100%;
                margin-bottom: 10px;
                margin-right: 0;
    
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        [data-appearance='full-width']:not(figure) {
            width: calc(100vw - var(--scrollbar-width));
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;

            .row-full-width-inner {
                .pagebuilder-column-group, .pagebuilder-overlay {
                    max-width: 1400px;
                    padding: 0 20px;
                    margin: 0 auto;
                }
            }
        }

        figure[data-content-type='image'] {
            img:last-child {
                display: none;
            }
        }

        .pagebuilder-banner-wrapper {
            display: flex;

            .pagebuilder-overlay {
                display: flex;
                align-items: center;
            }
        }

        [data-appearance='collage-left'] {
            .pagebuilder-banner-wrapper {
                display: flex;
                justify-content: flex-start;
            }
        }

        [data-appearance='collage-right'] {
            .pagebuilder-banner-wrapper {
                display: flex;
                justify-content: flex-end;
            }
        }

        [data-appearance='collage-centered'] {
            .pagebuilder-banner-wrapper {
                display: flex;
                justify-content: center;
            }
        }
        
        [data-appearance='poster'] {
            .pagebuilder-banner-wrapper {
                justify-content: center;
            }
        }
    }
}
