/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

$label-inset: 10px;
$item-size: 50px;

.ShoppingSteps {
    width: 50%;
    min-width: 540px;
    max-width: 680px;
    margin: 0 auto 40px;

    @include mobile {
        margin: 16px auto 40px;
        width: 100%;
        min-width: unset;
    }

    &-Wrapper {
        display: flex;
        justify-content: space-between;
    }

    &-Line {
        width: calc(100% - #{$item-size});
        position: absolute;
        transform: translate(-50%, -50%);
        inset-block-start: calc(50% - 3px);
        inset-inline-start: 50%;
        overflow: hidden;

        &::after {
            content: '..................................................................................';
            color: $text-color-3;
            letter-spacing: 8px;
            font-size: 18px;
        }

        @include mobile {
            inset-block-start: calc(50% - 13px);
        }
    }

    .ShoppingStep {
        &-Label {
            font-weight: 400;
            font-size: 16px;
            white-space: nowrap;
            background-color: white;
            padding-inline: $label-inset;
        }

        &-Number {
            width: $item-size;
            height: $item-size;
            border-radius: 50%;
            background-color: $background-color-3;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: relative;
        }

        &-Complete {
            position: relative;
            display: flex;
        }

        &-Item {
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            @include mobile {
                flex-direction: column;
            }

            &_isActive {
                .ShoppingStep {
                    &-Number {
                        background-color: $background-color-1;
                        color: white;
                        margin: 0;
                        
                        svg {
                            path {
                                stroke: $primary-color-1;
                            }
                        }
                    }
                }

                cursor: pointer;
            }

            &_isComplete {
                .ShoppingStep {
                    &-Number {
                        background-color: $primary-color-1;
                        
                        svg {
                            path {
                                stroke: $white;
                            }
                        }
                    }
                }

                cursor: pointer;
            }
        }
    }
}
