/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

[dir="ltr"] .Breadcrumb {   
    &,
    &-Separator,
    .Breadcrumb-Link-Name {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        padding-right: 0;
        margin: 0;
        display: flex;
        align-items: center;
        color: $text-color-2;
    }

    &:last-child {
        .Breadcrumb-Separator {
            display: none;
        }
        a:last-child {
            span:first-of-type {
                font-weight: 700;
                color: $text-color-2;
            }
        }
    }

    &-Link {
        &,
        &-Name {
            display: inline-flex;
            align-items: center;
        }
        .HomeIcon {
            path {
                stroke: $text-color-2;
            }
        }
    }

    &-Separator {
        margin: 0 4px;
    }
}
