.CosmeticLines {
    @include tablet {
        &-Items {
            #html-body .CmsPage & .pagebuilder-column-line {
                overflow-x: scroll;
                flex-wrap: nowrap;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }

                .pagebuilder-column {
                    min-width: 40vw;
                }
            }
        }
    }

    @include mobile {
        &-Header {
            #html-body & h3 {
                font-size: 32px;
                line-height: 120%;
                letter-spacing: -0.03em;
            }
    
            #html-body .CmsPage & .pagebuilder-column-line {
                flex-wrap: nowrap;
            }
        }

        &-Items {
            #html-body .CmsPage & .pagebuilder-column-line {
                overflow-x: scroll;
                flex-wrap: nowrap;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }

                .pagebuilder-column {
                    min-width: 70vw;
                }
            }
        }
    }
}
