/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ShowMoreLessContent {
    &-Wrapper {
        .ShowMoreLessContent {
            &-Button {
                font-weight: 700;
                color: $primary-color-1;
                cursor: pointer;
                width: auto;
                display: inline-flex;
                align-items: center;
                min-width: auto;
                font-size: 14px;
                line-height: 140%;
            }
        }

        .ArrowIcon {
            margin-left: 2px;

            path {
                stroke: $primary-color-1;
            }
        }
    }
}
