/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Newsletter {
    margin: auto;
    max-width: 439px;

    &-Wrapper {
        padding: 50px 16px;
        background: $black;
    }

    &-Heading {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 16px;

        h2, p {
            text-align: center;
            margin: 8px 0;
            color: $secondary-color-5;
            line-height: 120%;
        }

        p {
            font-size: 18px;

            @include mobile {
                font-size: 16px;
            }
        }
    }

    .Button {
        color: $black;
        background-color: $secondary-color-5;
        border-color: $secondary-color-5;
        width: 100%;
    }

    .FieldForm-Fields {
        margin-block-end: 16px;
        display: grid;
        grid-column-gap: 32px;
    }

    .Field {
        input, select {
            background-color: transparent;
            border-color: $secondary-color-5;
            color: $disabled-color-1;
        }

        .Field-Label {
            background-color: $black;
            color: $secondary-color-5;
        }
    }

    .Field-Wrapper {
        @include desktop {
            &:not(:nth-child(2)):not(:nth-child(3)) {
                grid-column: span 2;
            }
        }

        &_type_checkbox {
            display: none;
        }
    }
}
