/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ShowMore {
    &-ExpandableContentButton {
        justify-content: center;
    }

    &-ExpandableContentHeading {
        margin-right: 12px;
        text-decoration: underline;
        cursor: pointer;
    }

    &-Flex {
        &Row {
            display: flex;
            gap: 16px;

            @include mobile {
                flex-direction: column;
            }
        }

        &Item {
            text-align: left;
        }
    }

    &-Box {
        border: none;

        &:last-of-type {
            border: none;
        }
    }
}
