.HomepageUspTop {
    min-height: 125px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin: auto;

    @include mobile {
        overflow-x: auto;
        margin: 0 auto;
        justify-content: flex-start;

        @include hide-scrollbar;
    }

    @include desktop {
        column-gap: 20px;
        row-gap: 0;
    }

    &-Item {
        color: $black;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        width: 100%;

        @include mobile {
            min-width: 50%;
        }

        br {
            display: none;
        }

        img { 
            width: auto;
            height: auto;
            max-width: 80px;
        }
    }
}
