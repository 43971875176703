/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.FreeGiftList {
    margin-block-start: 32px;
    
    &-Header {
        margin-block-end: 6px;

        @include mobile {
            font-size: 32px;
        }
    }

    &-Subheader {
        @include mobile {
            margin-block-start: 10px;
            font-size: 16px;
        }
    }

    &-Table {
        @include mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-block-end: 20px;
        }

        &Head {
            display: grid;
            grid-template-columns: 3fr 1fr;
            background-color: transparent;
            font-size: 14px;
            font-weight: 600;
            padding: 10px 24px;
            margin-block-end: 0;
            grid-gap: 20px;
            border: 2px solid var(--powder-dark, #EFC1CE);
            border-bottom: none;
    
            @include mobile {
                display: none;
            }
    
            span {
                text-align: start;
    
                &:last-of-type {
                    text-align: end;
                }
            }
        }
    }

    &-Items {
        @include desktop {
            border: 2px solid var(--powder-dark, #EFC1CE);
            max-height: 580px;
            overflow-y: auto;
        }
    }

    &-ShowMoreBtn {
        margin-block-start: 16px;
        color: $text-color-2;
        font-size: 16px;
        text-decoration-line: underline;
    }
}
