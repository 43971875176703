.AboutUsBlock {
    position: relative;

    &-Button {
        transform: translateX(-50%);
        position: absolute;
        left: 50%;
        bottom: -120px;
        
        @include mobile {
            width: 90%;
        }

        .Button {
            border-color: $black;
            color: $black;
        }
    }

    @include mobile {
        #html-body & {
            padding-bottom: 100px;
        }

        .CmsPage & {
            h3 {
                font-size: 24px;
            }

            p {
                line-height: 100%;
            }
        }

        p {
            font-size: 18px;
        }

        &-Banner {
            .pagebuilder-column-line {
                flex-direction: column-reverse;
            }

            #html-body & &_image {
                padding-right: 0;
            }
        }
    }
}
