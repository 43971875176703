/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

[dir="ltr"] .ResetAttributes {
    @include desktop {
        margin-top: 20px;
    }

    @include mobile {
        margin: 20px 14px;
        margin-block-end: 0;
    }

    &-CloseIcon {
        display: flex;
        align-items: center;

        @include mobile {
            left: -2px;
        }

        .CloseIcon {
            height: 20px;
            width: 20px;
        }
    }

    &-AttributeText {
        left: 5px;
    }


    &-AttributeLabel,
    &-AttributeOption {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: $black;
    }
}
