/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.RadioButtonIcon {
    circle:nth-of-type(2) {
        fill: none;
        stroke: none;
    }

    &:hover,
    &_isActive {
        circle:nth-of-type(2) {
            fill: var(--primary-base-color);
            stroke: var(--primary-base-color);
        }
    }
}
