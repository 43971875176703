/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

[dir=ltr] {
    li {
        &[class^="Cybot"], &[class^="CookieCard"] {
            &::before {
                content: "";
                display: none;
                padding-inline-end: 0;
                inset-inline-start: 0;
            }
        }
    }
}

ul {
    >li {
        &[class^="Cybot"], &[class^="CookieCard"] {
            content: "";
        }
    }
}

label {
    &[for^="Cybot"], &[for^="CookieCard"] {
        display: initial;    
    }
}

img {
    &[class*="CybotExternalLinkArrow"] {
        width: initial;
    }
}
