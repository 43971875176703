/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.OrderComments {
    &-Wrapper {
        width: 100%;
        margin-block-end: 10px;
        padding: 10px 20px 15px 20px;
        background: $background-color-1;
        border-radius: 0 6px 0 0;
    }

    &-Header {
        display: flex;
        align-items: center;

        h4 {
            margin: 0 10px;
            font-family: $font-primary;
            font-size: 16px;
            font-style: normal;
        }
    }

    &-Field {
        width: 100%;
        margin-block-start: 10px;

        textarea {
            width: 100%;
            min-height: 80px;
            border-radius: 10px 0 0 0;
        }

        &_disabled {
            pointer-events: none;
            
            textarea {
                border-color: $primary-color-1;
                background-color: $background-color-1;
            }
        }
    }
}
