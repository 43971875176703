.HomepagePromotions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 30px;
    margin: 0 auto;
    flex-direction: column;
    
    @include desktop {
        margin: 0 auto;
        flex-direction: row;
    }

    @include mobile {
        margin: 0 auto 80px auto;
    }

    >div {
        @include desktop {
            max-width: 435px;
        }

        h2 {
            margin: 0 0 12px;
        }

        p {
            @include paragraph-l();

            margin: 0;
            color: $text-color-2;
        }

        .Button {
            margin-block-start: 20px;
            width: auto;

            @include mobile {
                margin: 0;
                position: absolute;
                inset-inline-start: 50%;
                transform: translateX(-50%);
                inset-block-end: -357px;
                height: 40px;

                &:not([disabled]) {
                    &:hover {
                        @include desktop {
                            height: 40px;
                        }
                    }
                }
            }
        }
    }

    &-ImageLink {
        width: 670px;
        max-width: 50%;

        @include mobile {
            max-width: unset;
            width: 100%;
        }

        &:hover {
            img {
                transition: 0.3s;
                filter: grayscale(50%);
            }
        }
    }

    img {
        @include mobile {
            width: 100%;
            height: 227px;
            margin-block-start: 50px;
            object-fit: cover;
        }
    }
}
