#html-body {
    [data-content-type="banner"] {
        .pagebuilder-banner-wrapper {
            .pagebuilder-banner-button {
                align-items: center;
                background-color: var(--button-background);
                border-radius: var(--button-border-radius);
                cursor: pointer;
                display: inline-flex;
                color: var(--button-color);
                font-size: 14px;
                font-weight: 700;
                justify-content: center;
                height: var(--button-height);
                line-height: normal;
                padding-inline: var(--button-padding);
                text-decoration: var(--button-text-decoration);
                text-transform: uppercase;
                transition-duration: .25s;
                transition-timing-function: ease-out;
                transition-property: background-color, color, border;
                will-change: background-color, color, border;
            
                @include mobile {
                    user-select: none;
                    width: 100%;
                }
            
                border: {
                    color: var(--button-border);
                    style: solid;
                    width: var(--button-border-width);
                }
            
                &:hover,
                &:focus {
                    text-decoration: var(--button-hover-text-decoration);
                }
            
                &:not([disabled]) {
                    &:hover {
                        @include desktop {
                            background-color: var(--button-hover-background);
                            border-color: var(--button-hover-border);
                            border-radius: var(--button-hover-border-radius);
                            color: var(--button-hover-color);
                            height: var(--button-hover-height);
                            padding-inline: var(--button-hover-padding);
                        }
                    }
                }
            
                &[disabled] {
                    @include button-disabled();
                }

                &.pagebuilder-button {
                    &-primary {
                        background-color: var(--hollow-button-background);
                        border-color: var(--hollow-button-border);
                        border-radius: var(--hollow-button-border-radius);
                        color: var(--hollow-button-color);
                        height: var(--hollow-button-height);
                        padding-inline: var(--hollow-button-padding);
                        text-decoration: var(--hollow-button-text-decoration);
                
                        &:not([disabled]) {
                            &:hover {
                                @include desktop {
                                    background-color: var(--hollow-button-hover-background);
                                    border-color: var(--hollow-button-hover-border);
                                    border-radius: var(--hollow-button-hover-border-radius);
                                    color: var(--hollow-button-hover-color);
                                    height: var(--hollow-button-hover-height);
                                    padding-inline: var(--hollow-button-hover-padding);
                                    text-decoration: var(--hollow-button-hover-text-decoration);
                                }
                            }
                        }
                    }

                    &-link {
                        --button-border: transparent;
                        --button-background: transparent;
                        --button-color: var(--primary-base-color);
                        --button-hover-border: transparent;
                        --button-hover-background: transparent;
                        --button-hover-color: var(--primary-base-color);
                        --button-padding: 0;
                        --button-hover-padding: 0;
                        --button-height: min-content;
                        --button-hover-height: min-content;

                        display: flex;
                        align-items: center;
                        margin: 0;
                        border: none;
                        border-bottom: 1px solid $primary-color-3;
                        padding-block-end: 0;
                        color: $black;
                        letter-spacing: 0.8px;
                        font-weight: normal;
                        text-align: start;
                        line-height: 110%;

                        &::after {
                            content: url(../icons/arrow-right.svg);
                            margin-inline-start: 7px;
                        }
                
                        &:active {
                            border-bottom: 1px solid $primary-color-3;
                        }
                
                        @include hoverable {
                            &:hover,
                            &:focus {
                                border-bottom: 1px solid $primary-color-3;
                            }
                        }
                    }
                }
            }
        }
    }
}
