/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.PostPage {
    &-Wrapper {
        padding-bottom: 50px;
    }

    &-MainImage {
        padding-bottom: 550px;

        @include mobile {
            padding-bottom: 75%;

            .Image-Image {
                object-fit: cover;
            }
        }
    }

    &-Description {
        font-size: 20px;
        font-weight: 700;
        line-height: 120%;
    }

    &-Info {
        margin: 10px 0 30px 0;
        color: $text-color-2;

        span {
            padding: 0 5px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    &-PublishedAt,
    &-Categories {
        border-left: 1px solid $text-color-2;
    }

    &-Content {
        p {
            font-size: 16px;
            line-height: 140%;
            text-align: justify;
        }

        ul {
            li {
                font-size: 16px;
                list-style-type: decimal;

                &::before {
                    content: none;
                }
            }
        }
    }

    &-Error {
        text-align: center;
        margin-top: 50px;
    }
}