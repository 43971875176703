#html-body {
    .NoPadding {
        padding: 0;
    }

    .NoPaddingSlider {
        .slick-list {
            padding: 20px 0;
        }
    }

    .Rounded {
        &TopLeft {
            border-radius: 30px 0 0 0;
        }

        &TopRight {
            border-radius: 0 30px 0 0;
        }

        &BottomRight {
            border-radius: 0 0 30px 0;
        }

        &BottomLeft {
            border-radius: 0 0 0 30px;
        }
    }

    .Hidden {
        display: none;

        &OnMobile {
            @include mobile {
                display: none;
            }
        }

        &OnDesktop {
            @include desktop {
                display: none;
            }
        }
    }

    .HeadingSeeMoreLink {
        &, a {
            font-weight: 400;
            font-size: 16px;
            line-height: 110%;
            letter-spacing: 0.05em;
            text-decoration-line: underline;
            color: $text-color-2;
        }
    }

    .HighlitedText {
        ul {
            max-width: 70%;

            li {
                display: flex;
                background-color: $success-color-1;
                border-radius: 0px 0px 10px 0px;

                &:last-child {
                    margin-block-end: 6px;
                }

                code {
                    color: $white;
                    margin-inline-start: auto;
                    padding-inline-end: 12px;
                }
            }
        }

        @include mobile {
            ul {
                max-width: 100%;
            }
        }

        &_AC1C42 {
            ul {
                li {
                    background-color: $primary-color-1;

                    a {
                        color: $white;

                        &:hover {
                            color: $secondary-color-3;
                        }
                    }
                }
            }
        }

        &_80122F {
            ul {
                li {
                    background-color: $primary-color-2;

                    a {
                        color: $white;

                        &:hover {
                            color: $secondary-color-3;
                        }
                    }
                }
            }
        }

        &_D04469 {
            ul {
                li {
                    background-color: $primary-color-3;

                    a {
                        color: $white;

                        &:hover {
                            color: $secondary-color-3;
                        }
                    }
                }
            }
        }

        &_364818 {
            ul {
                li {
                    background-color: $vegan-color;

                    a {
                        color: $white;

                        &:hover {
                            color: $secondary-color-3;
                        }
                    }
                }
            }
        }
    }
}
