/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@mixin expanded-content {
    opacity: 1;
    max-height: 100%;
}

.ExpandableContent {
    @include mobile {
        .ChevronIcon {
            width: 12px;
            height: 12px;
        }
    }

    &-Heading {
        font-weight: 400;
        font-size: 14px;
        line-height: 110%;
        letter-spacing: 0.05em;
        color: $black;
    }

    &-Content {
        transition-property: opacity;
        transition-duration: 200ms;

        @include mobile {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
        }

        @include desktop {
            margin-block-start: 0px;
        }

        &_isContentExpanded {
            @include mobile {
                @include expanded-content;
            }

            &.ExpandableContent-Content_allowDesktopExpandable {
                @include expanded-content;
            }
        }

        &_allowDesktopExpandable {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
        }
    }
    
    &-Button {
        &_alwaysVisible:not(.ExpandableContent-Button_isContentExpanded) {
            + .ExpandableContent-Content {
                max-height: 0;
                overflow: hidden;
                opacity: 0;
            }
        }
    }
}
