/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.homepage-slider {
    @include desktop {
        height: var(--slider-widget-height-desktop);
        min-height: var(--slider-widget-height-desktop);
        display: flex;
        flex-direction: column;
    }

    @include mobile {
        height: var(--slider-widget-height-mobile);
    }

    .HomeSlider {
        &-Subheading {
            @include paragraph-l();

            margin-block: 12px 0;            
            color: $text-color-2;

            @include mobile {
                @include paragraph-m();

                margin-block-start: 8px;
            }

            + p {
                margin: 0;
            }
        }
    }  
}
