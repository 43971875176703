/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.HomePage {
    .CmsPage {
        &-Heading {
            display: none;
        }

        &,
        &-Wrapper {
            margin-block-end: 0;
            margin-block-start: 0;
        }

        &-Wrapper {
            @include desktop {
                max-width: 100%;
                padding: 0;
                margin: 0;

                .CmsPage-Content {
                    >div>div {
                        &:not(:has(.full-width)):not(.SliderWidget) {
                            max-width: var(--content-wrapper-width);
                            padding: 0 $content-wrapper-padding-desktop;
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }

                    .SliderWidget {
                        max-width: 100%;
                    }
                }
            }
        }

        &-Content {
            @include mobile {
                overflow-x: unset;
            }
        }
    }

    .pagebuilder-column-group {
        img {
            @include mobile {
                object-fit: cover;
            }
        }
    }
}