/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Footer {
    &-Columns {
        padding: 25px $content-wrapper-padding-mobile 35px;

        @include desktop {
            padding: 40px $content-wrapper-padding-desktop;
        }
    }

    &-Column {
        @include desktop {
            flex: 1;
            max-width: 25%;
        }

        &:not(:last-of-type) {
            @include mobile {
                margin-block-end: 0;
            }
        }

        &_isExpandedByDefault.ExpandableContent .ExpandableContent-Button {
            pointer-events: none;

            .ChevronIcon {
                display: none;
            }
        }
    }

    &-ExpandableContentHeading>* {
        margin: 0;
        font-size: 20px;
        font-weight: 300;
        line-height: 120%;
        letter-spacing: -0.6px;
    }

    .ExpandableContent {
        @include mobile {
            border: 0;
        }

        &-Content {
            @include desktop {
                margin-block-start: 16px;
            }

            &_isContentExpanded {
                @include mobile {
                    margin: -10px 0 0;
                }
            }
        }

        &-Button {
            @include mobile {
                padding-block: 25px;
            }
        }    
    }

    &-ExpandableContentContent {
        p,
        p a,
        li a {
            font-size: 14px;
            line-height: 140%;
            color: $text-color-2;
            margin: 0;
            font-weight: 400;
        }

        li {
            margin-block-end: 0;

            &::before {
                display: none;
            }
        }

        p+p {
            margin-block-start: 10px;
        }

        li a {
            font-size: 14px;
            line-height: 140%;
            color: $text-color-2;
        }

        li+li {
            margin-block-start: 8px;
        }
    }

    &-Contact,
    &-Information,
    &-About,
    &-Socials {
        @include mobile {
            text-align: start;
        }
    }

    &-ContactIcon {
        max-width: 24px;
        margin-inline-end: 8px;
        vertical-align: middle;
    }

    &-Socials {
        .Footer-SocialsIcons+h5 {
            @include desktop {
                margin: 20px 0;
            }

            @include mobile {
                margin: 50px 0 20px;
                font-size: 20px;
                font-weight: 300;
                line-height: 120%;
                letter-spacing: -0.6px;
            }
        }

        p {
            font-size: 12px;
            line-height: 16px;
            color: $text-color-2;
            margin-block-end: 20px;
            max-width: 294px;

            @include mobile {
                font-weight: 600;
                line-height: 20px;
                font-size: 16px;
            }
        }

        .Button_isHollow {
            border-color: $black;
            color: $black;
            width: 100%;
        }
    }

    &-SocialsIcon {
        max-width: 40px;
        margin-inline-end: 16px;
    }

    &-CopyrightBlock {
        background-color: $secondary-color-4;
    }

    &-CopyrightContentWrapper {
        border-radius: 0 0 0 50px;

        @include mobile {
            border-radius: 0 0 0 25px;
        }
    }

    &-CopyrightContent {
        padding: 40px $content-wrapper-padding-desktop;
        justify-content: flex-start;

        @include mobile {
            padding: 15px $content-wrapper-padding-mobile;
            justify-content: center;
        }
    }

    &-Copyright {
        font-size: 14px;
        color: $black;
        line-height: 140%;
        padding: 0;
    }

    &-UspWrapper {
        background-color: $secondary-color-4;
    }

    &-UspContentWrapper {
        border-radius: 0 0 50px 0;
        background-color: $background-color-1;
        padding: 25px 0;

        @include mobile {
            border-radius: 0 0 25px 0;
            padding: 10px 0;
        }
    }

    &-AdditionalWrapper {
        padding: 0;
        background-color: $white;

        @include mobile {
            padding: 30px 0;
        }

        .ContentWrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            min-height: 100px;

            @include mobile {
                row-gap: 33px;
            }
        }
    }

    &-AdditionalContentTitle {
        font-size: 14px;
        line-height: 140%;
        margin-block-end: 10px;
    }

    &-AdditionalContentList {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        column-gap: 40px;

        @include mobile {
            column-gap: 50px;
            row-gap: 10px;
            justify-content: space-between;
        }

        li {
            @include grayscale();

            &::before {
                display: none;
            }

            &:hover {
                @include grayscale(unset);
            }
        }
    }

    &-AdditionalContent {
        &_isEcomiWidget {
            @include grayscale();

            &:hover {
                @include grayscale(unset);
            }

            @include mobile {
                width: 100%;
            }

            #widget-container > div {
                @include mobile {
                    margin-inline-start: 0;
                }

                .info1 {
                    inset-inline-end: -10px;
                }
            }
        }
    }

    &-Checkout {
        background: $secondary-color-4;

        .Footer-Copyright {
            @include mobile {
                margin-block-end: 40px;
            }
        }
    }

    &-CheckoutWrapper {
        min-height: 100px;
        background-color: var(--secondary-base-color);
        display: flex;
        border-radius: 0 0 0 50px;

        @include mobile {
            border-radius: 0 0 0 25px;
            margin-block-end: calc(var(--navigation-tabs-height) + 133px);
        }
    }

    &-CheckoutContentWrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include mobile {
            flex-direction: column;
            padding: 16px 8px 24px 8px;
        }
    }

    &-CheckoutContent {
        display: flex;
        align-items: center;
        gap: 20px;

        @include mobile {
            flex-direction: column;
        }

        li {
            margin-block-end: 0;

            &::before {
                display: none;
            }

            a {
                font-size: 14px;
                line-height: 24px;
                font-weight: 400;
                color: $black;

                &.PhoneWrapper {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
            }
        }
    }
}
